import React, { Component } from 'react'
import { FiDownloadCloud, FiLink } from 'react-icons/fi'
import './App.scss'
import ReactGA from 'react-ga'

const Link = ({onClick, href, children}) => (
  <a href={href} target='_blank' onClick={onClick} rel='noopener noreferrer'>
    <span><FiDownloadCloud size='1.5em' /></span>
    <div>{children}</div>
  </a>
)

class App extends Component {
  componentDidMount () {
    const debug = process.env.REACT_APP_GA_DEBUG === 'true'
    ReactGA.initialize('UA-65417453-6', {
      debug,
      titleCase: false
    })
    ReactGA.pageview('/')
  }

  trackOurWork () {
    ReactGA.event({
      category: 'Download',
      action: 'Our Work',
    })
  }

  trackOurPhilosophy () {
    ReactGA.event({
      category: 'Download',
      action: 'Our Philosophy',
    })
  }
  trackOurWebsite () {
    ReactGA.event({
      category: 'Navigate',
      action: 'weade.co',
    })
  }

  render () {
    return (
      <div className="App">
        <div>
          <h1>Hi! Thanks for Dropping By.</h1>
          <div>
            <p>
              We're glad that you made it here. If you want to know more about ADE here's a few
              documents
              that you can browse to understand what we do and how we do it.
            </p>
            <p>
              If you want to see some of our work you can look at  <a
              href='https://www.dropbox.com/s/wxypouhqz1irmf0/ADE-work.pdf?dl=1'
              target='_blank'><em>Our Work</em></a>. If you
              want
              to know more about <em>how</em> we work, take a look at<a
              href='https://www.dropbox.com/s/otl18wddej64o92/ADE%20-%20An%20Introduction%20.pdf?dl=1'
              target='_blank'><em>Our Philosophy</em></a>.
            </p>
          </div>
          <div className='file-icons'>
            <Link
              href='https://www.dropbox.com/s/wxypouhqz1irmf0/ADE-work.pdf?dl=1'
              onClick={this.trackOurPhilosophy.bind(this)}>
              Our Work
            </Link>
            <Link href='https://www.dropbox.com/s/otl18wddej64o92/ADE%20-%20An%20Introduction%20.pdf?dl=1'
              onClick={this.trackOurWork.bind(this)}>
              Our Philosophy
            </Link>
            <a href='http://weade.co' onClick={this.trackOurWebsite.bind(this)}>
              <span><FiLink size='1.5em' /></span>
              <div>weade.co</div>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default App
